import React from 'react'
import ActivitiesList from '../../../ActivitiesList'

const Activities = (props) => {
  return (
    <div id="activities">
      <div className="section-header">
        <div className="container">
          <div className="row section-title">
            <div className="columns twelve">
              <h3>Activity</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="columns twelve">
            <ActivitiesList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activities
