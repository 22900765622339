import React from 'react'
import { MdFileDownload } from 'react-icons/md'
import { filteredLeads } from '../../../../common/filtered-leads'

const LeadsExportButton = ({ data, filters, downloadCsv }) => {
  const onClick = () => {
    if (!data.leads) return

    const leads = filteredLeads(data.leads, filters)
    downloadCsv(leads)
  }

  return (
    <span onClick={onClick} className="btn link">
      <MdFileDownload />
      &nbsp; Download as CSV
    </span>
  )
}

export default LeadsExportButton
