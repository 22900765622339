import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ResponsiveTable from '../ResponsiveTable'
import { useMutation, useQuery } from '@apollo/client'
import { PairingsListQuery, TriagePairingMutation } from './queries'
import { GridReadyEvent, GridApi, RowClassParams } from 'ag-grid-community'
import {
  GQLPairing,
  TriageItem,
  GQLPairingStatusFilter,
  GQLPairingResponse,
} from './types'
import LeadBox from './components/LeadBox'
import ProjectBox from './components/ProjectBox'
import ScoreBox from './components/ScoreBox'
import TriageControls from './components/TriageControls'
import { GoalsListQuery } from '@/modules/ActivitiesList'
import { LeadListQuery } from '@/modules/LeadsCatalog'
import TriageStatus from './components/TriageStatus'
import { GQLProject, GQLLead } from '@/modules/Pairings/types'

type PairingsListProps = {
  onError: (message: string) => void
  onNotify: (message: string) => void
  refetch?: boolean
}

const PairingsList = (props: PairingsListProps) => {
  const { onError, onNotify, refetch } = props
  const {
    loading,
    error,
    data,
    refetch: refetchPairings,
  } = useQuery<GQLPairingResponse, GQLPairingStatusFilter>(PairingsListQuery, {
    variables: { triageStatus: 'PENDING' },
  })
  const [saveTriageStatus] = useMutation(TriagePairingMutation, {
    refetchQueries: [
      { query: GoalsListQuery },
      PairingsListQuery,
      LeadListQuery,
    ],
  })
  const currentUser = useSelector((state: any) => state.user)
  const [triaged, setTriaged] = useState<string[]>([])
  const [displayData, setDisplayData] = useState<GQLPairing[]>([])
  const [_gridApi, setGridApi] = useState<GridApi | null>(null)

  const { pairings } = data || {}

  useEffect(() => {
    if (refetch) {
      refetchPairings()
    }
  }, [refetch])

  useEffect(() => {
    const newDisplayData =
      pairings?.filter(
        (pairing: GQLPairing) => !triaged.includes(pairing.id),
      ) || []
    setDisplayData(newDisplayData)
  }, [pairings, triaged])

  const save = async (triageList: TriageItem[]) => {
    try {
      await saveTriageStatus({
        variables: {
          triageList,
        },
        update(cache, { data: { triagePairings } }) {
          triagePairings.forEach((pairing: any) => {
            // merge the partial type back into the Pairings cache
            const id = `Pairing:${pairing.id}`
            cache.modify({
              id,
              fields: {
                triageStatus(_cachedStatusVal) {
                  return pairing.triageStatus
                },
              },
            })
          })
        },
      })

      if (triageList.length < 2)
        onNotify(`Pairing ${triageList[0].triageStatus.toLowerCase()}.`)
      else
        onNotify(
          `Triage status updated to ${triageList[0].triageStatus} for ${triageList.length} pairings`,
        )
      setTriaged([...triaged, ...triageList.map((_) => _.id)])
      return true
    } catch (error) {
      console.error('valueSetter failed:', error)
      onError('Failed to update triage status. Please try again.')
      return false
    }
  }

  const getRowClass = (params: RowClassParams) => {
    if (
      params.data.lead.multiplicity.pairCount > 0 ||
      params.data.lead.multiplicity.candidateCount > 1
    ) {
      return 'multiples'
    }
    return null
  }

  const onGridReady = (params: GridReadyEvent) => {
    console.log('onGridReady', params.api)
    setGridApi(params.api)
  }

  const columnDefs = [
    {
      headerName: 'Lead ID',
      field: 'leadId',
      maxWidth: 80,
      minWidth: 50,
      sortable: true,
      valueFormatter: (params: any) => params.value,
      comparator: (a: GQLLead, b: GQLLead) => a.id.localeCompare(b.id),
    },
    {
      headerName: 'Lead',
      field: 'lead',
      cellRenderer: LeadBox,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: [
          {
            displayKey: 'semiFulltextSearch',
            displayName: 'contains text',
            predicate: (filterValue: string, cellValue: any) => {
              return new RegExp(filterValue, 'i').test(
                `${cellValue.projectName} ${cellValue.address}`,
              )
            },
          },
        ],
      },
      sort: 'asc',
      comparator: (a: GQLLead, b: GQLLead) =>
        a.projectName.localeCompare(b.projectName),
      flex: 3,
      minWidth: 250,
      valueFormatter: (params: any) => params.value,
    },
    {
      headerName: 'Matching WG Net Project',
      field: 'wgnetProject',
      cellRenderer: ProjectBox,
      wrapText: true,
      autoHeight: true,
      flex: 4,
      minWidth: 300,
      sortable: true,
      comparator: (a: GQLProject, b: GQLProject) =>
        a.name.localeCompare(b.name),
      valueFormatter: (params: any) => params.value,
    },
    {
      headerName: 'Match Score',
      field: 'score',
      cellRenderer: ScoreBox,
      maxWidth: 120,
      sortable: true,
      valueFormatter: (params: any) => params.value,
    },
    {
      headerName: 'Triage',
      field: 'triageStatus',
      cellRenderer: TriageStatus,
      valueGetter: (params: any) => params.data,
      flex: 2,
      minWidth: 100,
      cellEditor: TriageControls,
      editable: true,
      singleClickEdit: true,
      valueSetter: (params: any) => {
        // convention for propagating new name for split lead during triage
        const [newTriageStatus, newProjectName] = params.newValue.split('|')

        const oldValue = params.data.triageStatus
        const newValue = newTriageStatus

        if (oldValue !== newValue) {
          save([
            {
              id: params.data.id,
              triageStatus: newValue,
              triagedBy: currentUser.id,

              splitLeadToNewProjectName: newProjectName,
            },
          ])
        }
      },
    },
  ]

  if (loading || !data) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return (
    <div className="triage-view">
      <div className="scrollpane">
        <ResponsiveTable
          rowData={displayData}
          columnDefs={columnDefs}
          rowSelection="single"
          getRowClass={getRowClass}
          domLayout="normal"
          enableFilter={true}
          scrollbarSize={10}
          stopEditingWhenGridLosesFocus={false}
          rowDeselection={true}
          rowHeight={150}
          reactiveCustomComponents={true}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  )
}

export default PairingsList
