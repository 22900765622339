type Props = {
  value: number
}

// max poss score is 300 rn
const getScoreCategoryClass = (score: number) => {
  if (score < 100) return 'poor'
  if (score < 150) return 'moderate'
  if (score < 225) return 'good'
  return 'excellent'
}
export default ({ value: score }: Props) => {
  const percentileClass = getScoreCategoryClass(score)

  return (
    <div className="triage-item-cell score-box">
      <span className={`score ${percentileClass}`}>{percentileClass}</span>
    </div>
  )
}
