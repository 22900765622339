import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import { filteredLeads } from '../../common/filtered-leads'
import {
  transitionLead,
  clearTransition,
  resetFilters,
  filterByStatus,
} from './actions'
import LeadsCatalog from './components/LeadsCatalog'
import { TimeWindowType } from '../LeadsFilter/components/DateRange/types'

const mapStateToProps = (state) => {
  const { filters, transitionAction } = state
  return { filters, transitionAction }
}

const mapDispatchToProps = (dispatch) => {
  return {
    transitionLead: (status, lead) => dispatch(transitionLead(status, lead)),
    clearTransition: () => dispatch(clearTransition()),
    resetFilters: () => dispatch(resetFilters()),
    filterByStatus: (statusType) => dispatch(filterByStatus(statusType)),
  }
}

export const LeadListQuery = gql`
  query LeadList($timeWindowStart: DateTime, $timeWindowEnd: DateTime) {
    leads(timeWindowStart: $timeWindowStart, timeWindowEnd: $timeWindowEnd) {
      id
      priority
      status
      projectName
      developerName
      units
      clientType
      percentageSold
      closedAt
      closingOutcome
      createdAt
      updatedAt
      assignedUsers {
        id
        name
        avatarFilename
      }
      upcomingGoals {
        id
      }
      missedGoals {
        id
      }
    }
    activities(recent: true) {
      lead {
        id
      }
    }
  }
`

const LeadStatusUpdateMutation = gql`
  mutation LeadStatusUpdate($id: ID!, $status: LeadStatusType!) {
    changeLeadStatus(id: $id, status: $status) {
      id
      status
    }
  }
`

const ComponentWithData = graphql(LeadListQuery, {
  props: ({ ownProps: { filters }, data: { leads, activities, refetch } }) => {
    if (!leads) {
      return { leads: [] }
    }

    const augmentedLeads = leads.map((lead) => {
      const { id, upcomingGoals, missedGoals } = lead
      const leadActivities = activities.filter(({ lead }) => lead.id === id)
      return {
        ...lead,
        hasActivities: leadActivities.length > 0,
        activities: leadActivities,
        hasMissedGoals: missedGoals.length > 0,
        goalNeeded: upcomingGoals.concat(missedGoals).length === 0,
      }
    })

    return {
      leads: filteredLeads(augmentedLeads, filters),
      refetch,
    }
  },
  options: ({ filters }) => {
    return {
      forceFetch: true,
      variables: {
        timeWindowStart: filters?.startDate,
        timeWindowEnd: filters?.endDate,
      },
    }
  },
})(LeadsCatalog)

const ComponentWithMutation = graphql(LeadStatusUpdateMutation, {
  props: ({ mutate }) => ({
    onUpdateLeadStatus: ({ id, status }) =>
      mutate({
        variables: { id, status },
        refetchQueries: ['LeadList'],
      }),
  }),
})(ComponentWithData)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithMutation)

export default connector
