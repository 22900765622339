import React, { useState, useEffect } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { DropdownSelect } from '../../../core/components/DropdownSelect'

import { timeWindowMap, TimeWindowType } from './types'
import './styles.scss'

const DateRange = ({
  visible,
  minDate,
  maxDate,
  startDate,
  endDate,
  timeWindow,
  timeWindowIsCustom,
  onChange,
}) => {
  const selectedTimeWindow = {
    value: timeWindow,
    label: timeWindowMap[timeWindow].label,
  }

  const options = Object.keys(timeWindowMap)
    .filter((key) =>
      timeWindow === TimeWindowType.custom
        ? true
        : key != TimeWindowType.custom,
    )
    .map((key) => ({
      value: key,
      label: timeWindowMap[key].label,
    }))

  const onStartDateChanged = (date) => {
    const startDate = date ? moment(date).startOf('day').toDate() : null

    // redux state
    onChange({
      startDate,
      timeWindow: TimeWindowType.custom,
    })
  }

  const onEndDateChanged = (date) => {
    const endDate = date ? moment(date).endOf('day').toDate() : null

    // redux state
    onChange({
      endDate,
      timeWindow: TimeWindowType.custom,
    })
  }

  const handleTimeWindowChange = (selectedOption) => {
    // set the display start and end dates
    const { start, end } = timeWindowMap[selectedOption.value]

    // redux state
    onChange({
      timeWindow: selectedOption.value,
      startDate: start ? moment(start).startOf('day').toDate() : null,
      endDate: end ? moment(end).endOf('day').toDate() : null,
    })
  }

  if (!visible) return null

  return (
    <>
      <div id="tw-range-filter" className="filter columns two">
        <div className="label">Date Range</div>
        <div className="value">
          <div className="tw-control">
            <DropdownSelect
              options={options}
              value={selectedTimeWindow}
              onChange={handleTimeWindowChange}
            />
          </div>
        </div>
      </div>
      <div id="date-range-filter" className="filter columns two">
        <div className="label">&nbsp;</div>
        <div className="value">
          <DatePicker
            selected={startDate}
            placeholderText="Start"
            isClearable
            minDate={minDate}
            maxDate={maxDate}
            onChange={onStartDateChanged}
          />
          <DatePicker
            selected={endDate}
            placeholderText="End"
            isClearable
            minDate={minDate}
            maxDate={maxDate}
            onChange={onEndDateChanged}
          />
        </div>
      </div>
    </>
  )
}

export default DateRange
