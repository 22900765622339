import moment from 'moment'

export enum TimeWindow {
  past3Months = 'past3Months',
  past6Months = 'past6Months',
  pastYear = 'pastYear',
  past2Years = 'past2Years',
  allTime = 'allTime',
  custom = 'custom',
}

// js-friendly version
export const TimeWindowType = {
  [TimeWindow.past3Months]: 'past3Months',
  [TimeWindow.past6Months]: 'past6Months',
  [TimeWindow.pastYear]: 'pastYear',
  [TimeWindow.past2Years]: 'past2Years',
  [TimeWindow.allTime]: 'allTime',
  [TimeWindow.custom]: 'custom',
}

export type SelectOption = { value: String; label: String }

export const timeWindowMap: Record<
  TimeWindow,
  { label: string; start: string | null; end: string | null }
> = {
  [TimeWindow.past3Months]: {
    label: 'Up to 3 Months ago',
    start: moment().subtract(3, 'month').startOf('day').toISOString(),
    end: moment().endOf('day').toISOString(),
  },
  [TimeWindow.past6Months]: {
    label: 'Up to 6 Months ago',
    start: moment().subtract(6, 'month').startOf('day').toISOString(),
    end: moment().endOf('day').toISOString(),
  },
  [TimeWindow.pastYear]: {
    label: 'Up to 1 year ago',
    start: moment().subtract(1, 'year').startOf('day').toISOString(),
    end: moment().endOf('day').toISOString(),
  },
  [TimeWindow.past2Years]: {
    label: 'Up to 2 years ago',
    start: moment().subtract(2, 'year').startOf('day').toISOString(),
    end: moment().endOf('day').toISOString(),
  },
  [TimeWindow.allTime]: {
    label: 'All time',
    start: null,
    end: null,
  },
  [TimeWindow.custom]: {
    label: 'Custom',
    start: null,
    end: null,
  },
}
