import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import Moment from 'moment'
import sortBy from 'sort-array'

import ActivitiesList from './components/ActivitiesList'
import { aggregate } from '../../common/activities'

const mapStateToProps = (state) => {
  const { user } = state
  return { user }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export const GoalsListQuery = gql`
  query ActivitiesGoalsList(
    $assignedUserId: ID
    $timeWindowStart: DateTime
    $timeWindowEnd: DateTime
  ) {
    leads(
      assignedUserId: $assignedUserId
      timeWindowStart: $timeWindowStart
      timeWindowEnd: $timeWindowEnd
    ) {
      id
      projectName
      upcomingGoals {
        id
        leadId
        description
        deadline
      }
      missedGoals {
        id
        leadId
        description
        deadline
      }
    }
    activities(
      assignedUserId: $assignedUserId
      timeWindowStart: $timeWindowStart
      timeWindowEnd: $timeWindowEnd
    ) {
      id
      createdAt
      user {
        id
        name
        avatarFilename
      }
      type
      lead {
        id
        projectName
        closingOutcome
        closingReasons
        closedAt
        closingComment
      }
      diff
      isMultiDiff
      targets {
        __typename
        ... on Goal {
          id
          leadId
          deadline
          description
          complete
        }
        ... on Comment {
          id
          content
        }
        ... on LeadAssignment {
          user {
            id
            name
          }
        }
        ... on Lead {
          priority
          status
          projectName
          developerName
          wgnetClientName
          clientType
          salesStatus
          constructionStatus
          zoningDetail
          address
          developerRating
          units
          percentageSold
          salesStartedAt
          source
        }
      }
    }
  }
`

const GoalCompleteMutation = gql`
  mutation CompleteGoal($id: ID!, $leadId: Int!) {
    completeGoal(id: $id, leadId: $leadId) {
      id
    }
  }
`

const ComponentWithData = graphql(GoalsListQuery, {
  skip: ({ user }) => !user.id,
  options: ({ user }) => ({
    variables: {
      assignedUserId: user.id,

      // fixed at last 3 months
      timeWindowStart: Moment().subtract(3, 'months').startOf('day').toDate(),
      timeWindowEnd: Moment().endOf('day').toDate(),
    },
    pollInterval: 30000,
    forceFetch: true,
  }),
  props: ({ ownProps: { onComplete }, data: { leads, activities } }) => {
    if (!leads) return

    const augmentGoals = (lead, goals) => {
      return goals.map((goal) => ({
        ...goal,
        deadline: Moment(goal.deadline),
        onComplete: () => onComplete(goal),
        lead,
      }))
    }

    const augmentedLeads = leads.map((lead) => {
      return {
        ...lead,
        missedGoals: augmentGoals(lead, lead.missedGoals),
        upcomingGoals: augmentGoals(lead, lead.upcomingGoals),
      }
    })

    const goalsData = augmentedLeads.reduce(
      (accumulator, lead) => {
        const { missedGoals, upcomingGoals } = lead
        accumulator.missedGoals = accumulator.missedGoals.concat(missedGoals)
        accumulator.upcomingGoals =
          accumulator.upcomingGoals.concat(upcomingGoals)

        if (missedGoals.concat(upcomingGoals).length === 0) {
          accumulator.needGoals.push(lead)
        }

        return accumulator
      },
      {
        missedGoals: [],
        upcomingGoals: [],
        needGoals: [],
      },
    )

    return {
      missedGoals: sortBy(goalsData.missedGoals, ['deadline']),
      upcomingGoals: sortBy(goalsData.upcomingGoals, ['deadline']),
      needGoals: goalsData.needGoals,
      activities: aggregate(activities),
    }
  },
})(ActivitiesList)

const ComponentWithGoalCompleteMutation = graphql(GoalCompleteMutation, {
  props: ({ mutate }) => ({
    onComplete: ({ id, leadId }) =>
      mutate({
        variables: { id, leadId },
        refetchQueries: ['ActivitiesGoalsList'],
      }),
  }),
})(ComponentWithData)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithGoalCompleteMutation)

export default connector
