import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import './styles.scss'

import ResponsiveTable from '../../../ResponsiveTable'
import {
  LeadStatusTypes,
  LeadPriorityTypes,
} from '../../../../common/enumerations'

class LeadsList extends Component {
  render() {
    const { leads } = this.props
    const leadsList = leads.map((lead) => ({
      ...lead,
      createdAt: Moment(lead.createdAt).fromNow(),
      status: LeadStatusTypes[lead.status].label,
      assignedUsers: lead.assignedUsers.map((user) => user.name).join(', '),
      priority: LeadPriorityTypes[lead.priority],
    }))

    const columns = [
      {
        cellRenderer: (param) => {
          return <Link to={`/leads/${param.data.id}`}>{param.value}</Link>
        },
        field: 'projectName',
        headerName: 'Project Name',
        sort: 'asc',
        sortable: true,
      },
      {
        field: 'developerName',
        headerName: 'Client',
        sortable: true,
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        width: 100,
      },
      {
        field: 'units',
        headerName: 'Units',
        sortable: true,
        width: 80,
      },
      {
        field: 'percentageSold',
        headerName: '% Sold',
        sortable: true,
        width: 80,
      },
      {
        field: 'assignedUsers',
        headerName: 'Project Owner(s)',
        sortable: true,
      },
      {
        field: 'priority',
        headerName: 'Priority',
        sortable: true,
        width: 80,
      },
      {
        field: 'createdAt',
        headerName: 'Created On',
        sortable: true,
        width: 110,
      },
    ]

    return (
      <div id="leads-list">
        <ResponsiveTable
          rowData={leadsList}
          columnDefs={columns}
          paginationPageSize={25}
        />
      </div>
    )
  }
}

export default LeadsList
