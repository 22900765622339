import {
  FILTERS_UPDATED,
  PROJECT_TRACKER_FILTERS_UPDATED,
  CLEAR_FILTERS,
} from '../action-types'
import { TimeWindowType } from '../../LeadsFilter/components/DateRange/types'

const defaultFiltersState = {
  projectName: null,
  clientType: null,
  activityType: null,
  assignedUser: null,
  developerName: null,
  percentageSold: null,
  minUnits: null,
  maxUnits: null,
  timeWindow: TimeWindowType.past2Years,
  startDate: null,
  endDate: null,
  statusType: null,
  priorityType: null,
}

export const filters = (state = defaultFiltersState, action) => {
  switch (action.type) {
    case FILTERS_UPDATED:
      return { ...state, ...action.payload }
    case CLEAR_FILTERS:
      return defaultFiltersState
    default:
      return state
  }
}

const defaultProjectTrackerFilters = {
  startDate: null,
  endDate: null,
}

export const projectTrackerFilters = (
  state = defaultProjectTrackerFilters,
  action,
) => {
  switch (action.type) {
    case PROJECT_TRACKER_FILTERS_UPDATED:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default {
  filters,
  projectTrackerFilters,
}
