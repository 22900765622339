import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { downloadCsv } from './actions'
import LeadsExportButton from './components/LeadsExportButton'
import { timeWindowMap } from '../LeadsFilter/components/DateRange/types'

const mapStateToProps = (state) => {
  const { filters } = state
  return {
    filters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadCsv: (leads) => dispatch(downloadCsv(leads)),
  }
}

const LeadListQuery = gql`
  query LeadList($timeWindowStart: DateTime, $timeWindowEnd: DateTime) {
    leads(timeWindowStart: $timeWindowStart, timeWindowEnd: $timeWindowEnd) {
      id
      priority
      status
      projectName
      developerName
      units
      clientType
      percentageSold
      closedAt
      closingOutcome
      salesStartedAt
      salesStatus
      constructionStatus
      zoningDetail
      createdAt
      updatedAt
      assignedUsers {
        id
        name
      }
    }
  }
`

const ComponentWithData = graphql(LeadListQuery, {
  options: { forceFetch: true },
})(LeadsExportButton)

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithData)

export default connector
