import React, { Component } from 'react'
import Modal from '../../components/Modal'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import withRouter from '../../withRouter'
import './styles.scss'

import client from '../../../../common/graphql-api'

import LeadsCatalog from '../../../LeadsCatalog'
import LeadsFilter from '../../../LeadsFilter'
import LeadsExportButton from '../../../LeadsExportButton'
import LeadsUploadButton from '../../../LeadsUploadButton'
import PairingsIndicatorButton from './PairingsIndicatorButton'
import Pairings from '../../../Pairings'

class Leads extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filtersClass: 'hidden',
      pairingsModalIsOpen: false,
    }

    this.toggleFilters = this.toggleFilters.bind(this)
    this.togglePairingsModal = this.togglePairingsModal.bind(this)
  }

  toggleFilters() {
    this.setState({
      ...this.state,
      filtersClass: this.state.filtersClass === 'hidden' ? '' : 'hidden',
    })
  }

  togglePairingsModal() {
    this.setState({
      ...this.state,
      pairingsModalIsOpen: !this.state.pairingsModalIsOpen,
    })
  }

  render() {
    const { listView, router } = this.props
    const { filtersClass } = this.state

    const title = listView ? 'Leads List' : 'Leads'

    const FilterExpand = filtersClass === 'hidden' ? MdExpandMore : MdExpandLess

    const keepFiltersOnMount =
      router.searchParams.get('keepFilters') !== undefined

    return (
      <div id="leads">
        <div className="section-header">
          <div className="container">
            <div className="row section-title">
              <div className="columns eight">
                <h3>{title}</h3>
              </div>
              <div className="columns four align-right">
                <div className="btnrow">
                  <span className="hide-mobile">
                    <LeadsUploadButton
                      onComplete={() => {
                        client.refetchQueries({
                          include: ['LeadList'],
                        })
                      }}
                    />
                  </span>
                  <span className="hide-mobile">
                    <PairingsIndicatorButton
                      onClick={this.togglePairingsModal}
                    />
                  </span>
                </div>
                <span className="hide-desktop">
                  <span className="btn link" onClick={this.toggleFilters}>
                    Filter Options <FilterExpand />
                  </span>
                </span>
              </div>
            </div>
            <LeadsFilter
              className={filtersClass}
              enabledFilters={{
                projectName: true,
                clientType: !listView,
                activityType: !listView,
                assignedUser: true,
                developerName: true,
                percentageSold: true,
                units: true,
                dateRange: true,
                statusType: listView,
              }}
            />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="columns six">
              <LeadsFilter
                className={filtersClass}
                enabledFilters={{ priorityType: true }}
              />
            </div>
            <div className="columns six align-right hide-mobile">
              <LeadsExportButton />
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <LeadsCatalog
                listView={listView}
                keepFiltersOnMount={keepFiltersOnMount}
              />
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <Modal
                id="triage"
                isOpen={this.state.pairingsModalIsOpen}
                onClose={this.togglePairingsModal}
                label="Triage Lead-Project Pairings"
                profile="fullscreen">
                <Pairings refetch={this.state.pairingsModalIsOpen} />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Leads)
