import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import './styles.scss'

import ProjectName from '../ProjectName'
import ClientType from '../ClientType'
import ActivityType from '../ActivityType'
import AssignedUser from '../AssignedUser'
import DeveloperName from '../DeveloperName'
import PercentageSold from '../PercentageSold'
import UnitRange from '../UnitRange'
import DateRange from '../DateRange'
import PriorityType from '../PriorityType'
import StatusType from '../StatusType'
import { timeWindowMap } from '../DateRange/types'

const LeadsFilter = ({
  className,
  enabledFilters,
  filters,
  leads,
  updateFilters,
  users,
}) => {
  const timeWindow = filters.timeWindow

  const [projectNames, setProjectNames] = useState([])
  const [userList, setUserList] = useState([])
  const [developerNames, setDeveloperNames] = useState([])
  const [lowestUnitCount, setLowestUnitCount] = useState(null)
  const [highestUnitCount, setHighestUnitCount] = useState(null)
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)

  useEffect(() => {
    const projectNames = []
    const userList = []
    const developerNames = []
    let lowestUnitCount
    let highestUnitCount
    let minDate = null
    let maxDate = Moment().endOf('day').toDate()

    if (leads) {
      leads.forEach((lead) => {
        projectNames.push(lead.projectName)
        if (developerNames.indexOf(lead.developerName) === -1) {
          developerNames.push(lead.developerName)
        }
        if (lead.units < lowestUnitCount || !lowestUnitCount)
          lowestUnitCount = lead.units
        if (lead.units > highestUnitCount || !highestUnitCount)
          highestUnitCount = lead.units
        const createdAt = Moment(lead.createdAt)
      })
    }

    if (users) {
      users.forEach((user) => {
        userList.push({ value: user.id, label: user.name, user })
      })
    }

    setProjectNames(projectNames)
    setUserList(userList)
    setDeveloperNames(developerNames)
    setLowestUnitCount(lowestUnitCount)
    setHighestUnitCount(highestUnitCount)
    setMinDate(minDate)
    setMaxDate(maxDate)
  }, [leads, users])

  const display = Object.keys(enabledFilters).some((key) => enabledFilters[key])

  if (!display) return <span>&nbsp;</span>

  const {
    projectName,
    clientType,
    activityType,
    assignedUser,
    developerName,
    percentageSold,
    units,
    dateRange,
    statusType,
    priorityType,
  } = enabledFilters

  return (
    <div className={`filters row ${className}`}>
      <DateRange
        minDate={minDate}
        maxDate={maxDate}
        startDate={filters.startDate}
        endDate={filters.endDate}
        visible={dateRange}
        timeWindow={filters.timeWindow}
        timeWindowIsCustom={filters.timeWindowIsCustom}
        onChange={updateFilters}
      />
      <ProjectName
        projectNames={projectNames}
        projectName={filters.projectName}
        visible={projectName}
        onChange={updateFilters}
      />
      <ClientType
        clientType={filters.clientType}
        visible={clientType}
        onChange={updateFilters}
      />
      <ActivityType
        activityType={filters.activityType}
        visible={activityType}
        onChange={updateFilters}
      />
      <AssignedUser
        userList={userList}
        assignedUser={filters.assignedUser}
        visible={assignedUser}
        onChange={updateFilters}
      />
      <DeveloperName
        developerNames={developerNames}
        developerName={filters.developerName}
        visible={developerName}
        onChange={updateFilters}
      />
      <PercentageSold
        percentageSold={filters.percentageSold}
        visible={percentageSold}
        onChange={updateFilters}
      />
      <UnitRange
        lowestUnitCount={lowestUnitCount}
        highestUnitCount={highestUnitCount}
        minUnits={filters.minUnits}
        maxUnits={filters.maxUnits}
        visible={units}
        onChange={updateFilters}
      />
      <PriorityType
        priorityType={filters.priorityType}
        visible={priorityType}
        onChange={updateFilters}
      />
      <StatusType
        statusType={filters.statusType}
        visible={statusType}
        onChange={updateFilters}
      />
    </div>
  )
}

LeadsFilter.propTypes = {
  className: PropTypes.string,
  enabledFilters: PropTypes.shape({
    projectName: PropTypes.bool,
    clientType: PropTypes.bool,
    activityType: PropTypes.bool,
    assignedUser: PropTypes.bool,
    developerName: PropTypes.bool,
    percentageSold: PropTypes.bool,
    units: PropTypes.bool,
    dateRange: PropTypes.bool,
    statusType: PropTypes.bool,
    priorityType: PropTypes.bool,
  }),
}

LeadsFilter.defaultProps = {
  enabledFilters: {
    projectName: false,
    clientType: false,
    activityType: false,
    assignedUser: false,
    developerName: false,
    percentageSold: false,
    units: false,
    dateRange: false,
    statusType: false,
    priorityType: false,
  },
}

export default LeadsFilter
